.container
  position: relative
  margin-bottom: 10px
  padding: 10px
  padding-top: 18px
  text-align: center
  border: 1px solid #ddd
  border-radius: 4px

  h2
    position: absolute
    top: -8px
    left: 50%
    padding: 0 10px
    font-size: 16px
    background-color: #fff
    z-index: 100
    transform: translateX(-50%)
    font-weight: bold
