.container
  margin-bottom: 20px

.offerSelector
  display: flex
  margin-bottom: 20px
  padding: 5px
  border-radius: 4px
  background-color: #f1efef
  border: 1px solid #dedede

.offerSelectorButton
  width: 50%
  padding: 8px
  text-align: center
  font-weight: bold
  font-size: 14px
  text-transform: uppercase
  font-family: "Montserrat", sans-serif
  user-select: none
  border: none
  background-color: transparent
  border-radius: 4px

  p
    color: black

    span
      display: block
      color: red

  b
    color: #929292
    font-size: 12px

.offerSelectorButtonActive
  background-color: #fff
  color: #23a2ed
  box-shadow: 0 0 6px 2px #00000029

.products
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.product
  position: relative
  display: flex
  align-items: center
  width: calc(50% - 10px)
  margin: 5px
  padding: 10px
  box-shadow: 0 0 8px #00000029
  cursor: pointer
  overflow: hidden
  border: 1px solid #858585
  border-radius: 4px

  @media (max-width: 768px)
    width: 100%
    margin: 0
    margin-bottom: 10px

.productActive
  border: 3px solid #25a2ed

.productPopular
  position: absolute
  width: 200px
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  background-color: #25a2ed
  color: #fff
  font-size: 12px
  font-weight: 700
  line-height: 16px
  text-transform: uppercase
  transform: rotate(-33deg)
  inset: 20px auto auto -51px
  z-index: 1

.productBestValue
  position: absolute
  width: 200px
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  background-color: #fcc506
  color: #000
  font-size: 12px
  font-weight: 700
  line-height: 16px
  text-transform: uppercase
  transform: rotate(-33deg)
  inset: 20px auto auto -51px
  z-index: 1

.productSoldOut
  user-select: none
  pointer-events: none
  cursor: not-allowed
  opacity: 0.5

.productSoldOutBadge
  position: absolute
  width: 200px
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  background-color: #dfdfdf
  color: #000
  font-size: 12px
  font-weight: 700
  line-height: 16px
  text-transform: uppercase
  transform: rotate(-33deg)
  inset: 20px auto auto -51px
  z-index: 1

.productImage
  max-width: 200px
  margin-right: 10px

.productContent
  display: flex
  flex-direction: column
  text-align: center

  b
    margin-bottom: 10px
    font-size: 32px

  p
    margin-bottom: 5px
    font-size: 14px
    line-height: 1.2

    strong
      font-weight: normal

  s
    margin-bottom: 5px
    color: #9aa0ab

  span
    color: #00c249
    font-size: 24px
    font-weight: 700
