@charset "utf-8";

/* CSS Document */
/*----------------
RESET CSS
-----------------*/

img {
  border: none;
  height: auto;
}

.img-full {
  max-width: 100%;
}

/*=====MAIN CSS START=====*/

.shopifyTemplate {
  text-align: center;
  font-family: 'Noto Sans', serif;
  font-weight: 400;
  font-style: normal;
}

.container {
  padding: 0 5%;
  width: 90%;
  max-width: 70.57143em;
  margin: 0 auto;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  display: flex;
  box-sizing: content-box;
  flex: 1;
}

/*==========================*/
.formMain {
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.checkoutLeft {
  width: 49%;
  padding-right: 5%;
  padding-top: 2em;
  padding-bottom: 60px;
  box-sizing: content-box;
}

.checkoutRight {
  width: 44%;
  padding-left: 5%;
  padding-top: 4.5em;
  padding-bottom: 60px;
  position: relative;
  box-sizing: content-box;
}
.checkoutRight:after {
  content: '';
  display: block;
  width: 300%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #f6f6f7;
  z-index: -1;
}

/*-----------------------
CSS FOR SEC1
-------------------------*/
.logoBox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 50px;
  max-width: 50%;
  object-fit: cover;
}
.secureLock {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.2;
  color: #787878;
  text-align: left;
  letter-spacing: 0.3px;
  padding-left: 45px;
  text-transform: uppercase;
}
.secureLock img {
  width: 40px;
  margin-right: 5px;
  filter: grayscale(100%);
}
.viewBox {
  width: 100%;
  margin: 40px auto 0;
  background: #fff1aa;
  border: 1px solid #e3d37f;
  border-radius: 5px;
  padding: 10px 15px;
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.viewBoxColumn1 {
  width: 26px;
  position: relative;
  text-align: center;
}
.viewBoxColumn1 img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.viewBoxColumn2 {
  padding-left: 10px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: bold;
}

.packageBlock {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 30px;
}
.switchOptionBox {
  width: 100%;
  background: #ededed;
  border-radius: 5px;
  margin: 10px auto 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 15px;
}
.switchOption {
  width: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subscribeSaveText {
  color: #f00;
}
.subscribeShipText {
  font-size: 12px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}
.switchOption.active {
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

/*----------
Package
----------*/
.packageSection {
  width: 100%;
  margin-top: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 2%;
  row-gap: 18px;
}
.package {
  width: 49%;
  border-radius: 5px;
  background: #fff;
  border: 2px solid #b5b5b5;
  position: relative;
  padding: 0 15px 25px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 35px 15px;
  overflow: hidden;
  cursor: pointer;
}
.package.active {
  border-color: #25a2ed;
  box-shadow: 0 4px 5px 0 #c2d5e1;
}
.packageOfferName {
  position: absolute;
  top: 18px;
  left: -42px;
  background: #fcc506;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  width: 200px;
  transform: rotate(-26deg);
  padding: 3px 0;
}
.mostPopular {
  background: #25a2ed;
  color: #fff;
}
.packageProduct {
  width: 120px;
}
.packageProductImage {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.packageProductInfo {
  width: calc(100% - 120px);
  text-align: center;
}
.packageProductInfoText1 {
  font-size: 31px;
  line-height: 37px;
  color: #000;
  font-weight: 700;
}
.packageProductInfoQuantity {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 700;
  margin-top: 5px;
}
.packageProductInfoCutPrice {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-top: 5px;
  position: relative;
}
.packageProductInfoCutPrice:after {
  content: '';
  position: absolute;
  border-top: 2px solid #a3a3a3;
  top: 10px;
  left: -2px;
  right: -2px;
}
.packageProductInfoOfferPrice {
  font-size: 27px;
  line-height: 32px;
  color: #000;
  font-weight: 700;
  margin-top: 5px;
}

.expressCheckoutBox {
  width: 100%;
  margin-top: 25px;
}
.expressCheckoutText1 {
  font-size: 16px;
  line-height: 22px;
  color: #000;
}
.expressCheckoutButton {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffc33a;
  height: 60px;
  margin: 10px auto 0;
  border-radius: 5px;
}
.expressCheckoutButton img {
  width: auto;
  height: 32px;
}
.expressCheckoutText2 {
  font-size: 14px;
  line-height: 20px;
  color: #606466;
  margin-top: 20px;
  text-align: left;
}
.orText {
  font-size: 17px;
  line-height: 23px;
  color: #bdbcbc;
  margin-top: 25px;
  position: relative;
}
.orText span {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  padding: 0 20px;
  position: relative;
}
.orText:before {
  content: '';
  position: absolute;
  top: 12px;
  border-top: 1px solid #ccc;
  left: 0;
  right: 0;
}

.contactInformation,
.deliveryInformation {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: 35px;
}
.headingBox {
  width: 100%;
  margin-bottom: 15px;
}
.checkoutHeading {
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  font-weight: 700;
  text-align: left;
}
.formElement {
  width: 100%;
  display: flex;
}
.formField {
  position: relative;
  width: 100%;
  margin: 7px 0;
  text-align: left;
}

.halfField,
.paymentHalfField {
  width: calc(50% - 5px);
}

.paymentHalfField:last-child {
  margin-left: 10px;
}

.formField label {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 6px;
  left: 15px;
  font-size: 10px;
  color: #6e6e6e;
  transition: all 0.2s ease-out;
}

.formFieldActive .inputField,
.formFieldActive .selectField {
  padding-top: 18px;
  padding-bottom: 12px;
}

.formFieldActive label {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-out;
}

.inputField,
.selectField {
  width: 100%;
  outline: none;
  border: 1px solid #c9c9c9;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  font-family: 'Noto Sans', serif;
  font-weight: 400;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  box-sizing: border-box;
  font-size: 16px;
  -webkit-appearance: none;
}
.selectField {
  background: url('./images/select-arrow.png') no-repeat right 10px center #fff;
  padding-right: 25px;
}
.inputField::placeholder,
.selectField::placeholder {
  color: #6e6e6e;
  font-size: 14px;
}

.inputField:focus,
.selectField:focus {
  border: 1px solid #096795;
  box-shadow: 0 0 0 1px #096795;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.questionIcon {
  position: absolute;
  right: 15px;
  width: 17px;
  top: 50%;
  margin-top: -8px;
  cursor: pointer;
}
.tooltip {
  position: absolute;
  bottom: 82%;
  right: -40px;
  width: 128px;
  padding: 8px 10px 10px;
  border-radius: 5px;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #fff;
  z-index: 200;
  -webkit-transition: all 0.8s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.8s cubic-bezier(0.3, 0, 0, 1);
  -webkit-backface-visibility: hidden;
  background-color: #020202;
  visibility: hidden;
  opacity: 0;
}
.callQuestion:hover .tooltip {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.8s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.8s cubic-bezier(0.3, 0, 0, 1);
}
.tooltip:after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 6px solid #020202;
}

.billingSame {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: 15px;
}
.labelCheckbox {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: left;
  cursor: pointer;
  margin-bottom: 15px;
  color: #000000;
  font-size: 14px;
}
.allCheckbox {
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
  border-radius: 3px;
  outline: none;
  position: relative;
  margin-right: 7px;
  margin-top: -4px;
  -webkit-box-shadow: 0 0 0 0 #000000 inset;
  box-shadow: 0 0 0 0 #000000 inset;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.allCheckbox:checked {
  background: #326ec7;
  border: 1px solid #326ec7;
}
.allCheckbox:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 18px;
  background: url('./images/tik.svg') no-repeat center center;
  left: 1px;
  top: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
}
.allCheckbox:checked:after {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.submitContainer {
  width: 100%;
  margin-top: 30px;
}
.submitButton {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background: #f67a01;
  border-radius: 5px;
  height: 75px;
  color: #fff;
  font-weight: 700;
  font-size: 26px;
  line-height: 73px;
  -webkit-appearance: none;
  outline: none;
  letter-spacing: 0.3px;
  font-family: 'Noto Sans', serif;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}

.submitButton:disabled {
  background: #ccc;
  cursor: progress;
}

.submitButton img {
  display: inline-block;
  vertical-align: middle;
  margin: -10px 20px 0 0;
  width: 20px;
  filter: invert(1);
}
.saveCheckoutImage {
  display: block;
  margin: 25px auto 0;
  max-width: 100%;
}

/*RIGHT SIDE*/

.orderBox {
  width: 100%;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto 0;
}
.orderLeft {
  width: 78%;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.orderLeftProductBox {
  width: 90px;
  position: relative;
  border: 1px solid #096795;
  border-radius: 5px;
  background: #e8eff6;
  padding: 15px 10px;
}
.orderLeftProductBox img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}
.orderProductCount {
  right: -5px;
  height: 20px;
  width: 20px;
  line-height: 18px;
  top: -7px;
  position: absolute;
  background-color: #7d7b7b;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}
.orderProductInfo {
  width: calc(100% - 90px);
  text-align: left;
  padding-left: 15px;
}
.orderProductInfoText1 {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #000;
  font-weight: 700;
}
.orderProductInfoText2 {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #000;
  margin-top: 5px;
}
.orderProductPrice {
  width: 20%;
  text-align: right;
}
.orderProductPriceText {
  font-size: 17px;
  line-height: 23px;
  color: #000;
  margin-top: 5px;
  font-weight: 700;
}
.orderProductCutPrice {
  font-size: 17px;
  line-height: 23px;
  color: #000;
}
.orderProductCutPrice span {
  display: inline-block;
  position: relative;
}
.orderProductCutPrice span:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 12px;
  border-top: 1px solid #f00;
  transform: rotate(-15deg);
}

.cartBox {
  width: 100%;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin: 20px 0 10px;
  padding: 10px 0;
}
.cartTable {
  width: 100%;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 3px;
  position: relative;
}
.cartTable span {
  font-size: 16px;
  color: #323232;
  font-weight: 500;
}
.totalTextLeft {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}
.cartTable .totalText span {
  font-size: 18px;
  color: #191919;
  font-weight: bold;
}
.shippingInformation {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: 35px;
}
.tableQuestionIcon {
  display: inline-block;
  vertical-align: middle;
  margin: -3px 0 0 5px;
  position: relative;
}
.tableQuestionIcon img {
  width: 17px;
}
.toolTips {
  position: absolute;
  left: -50px;
  top: -73px;
  width: 115px;
  background: #000;
  border-radius: 5px;
  padding: 7px 5px 8px;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s linear;
}
.toolTips:before {
  content: '';
  position: absolute;
  border-top: 8px solid #000;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.tableQuestionIcon:hover .toolTips {
  visibility: visible;
  opacity: 1;
  transition: all 0.6s linear;
}

/*------------------------
CSS FOR PAYMENT PART
-------------------------*/
.paymentOptionBox {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.paymentCardsBox {
  width: 100%;
  background: #dcf7fd;
  padding: 13px 15px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paymentCardsBox.open {
  border-bottom: 1px solid #d9d9d9;
}
.paymentCardsBox p {
  font-weight: 500;
  color: #333333;
  font-size: 17px;
}
.paymentCardsBox img {
}
.paymentCardsBox small {
  margin: 5px 0 0 5px;
}
.paymentFieldsBox {
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding: 15px 20px;
  background-color: #f7f7f7;
}
.lockBackground,
.helpIcon {
  color: #919191;
  width: 17px;
  position: absolute;
  right: 15px;
  top: 0px;
  cursor: pointer;
}
.formField.cardNameField {
  width: 46%;
}
.formField.cardSmallField {
  width: 25%;
  margin-left: 2%;
}

.paymentButton {
  width: 50%;
  text-align: left;
  font-size: 15px;
  line-height: 21px;
  color: #000;
  font-weight: 700;
}
.paymentButton input[type='radio'] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 3px solid #326ec7;
  border-radius: 50%;
  outline: none;
  margin-top: -3px;
}
.paymentButton input[type='radio']:checked {
  background: #dcf7fd;
}
.paymentButton input[type='radio']:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dcf7fd;
  position: absolute;
  content: '';
  left: 1px;
  top: 1px;
}
.paypalSvg {
  display: inline-block;
  vertical-align: middle;
}
.paypalText {
  width: 100%;
  padding: 0 12%;
  padding-bottom: 20px;
  color: #545454;
  font-size: 14px;
  line-height: 20px;
}

.termsConditionsRow {
  width: 100%;
  margin: 25px auto 0;
  display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.termsConditionsRow input[type='checkbox'] {
  width: 22px;
  height: 22px;
  outline: none;
  border-radius: 3px;
  margin-top: 3px;
  cursor: pointer;
  border: 1px solid #000;
  -webkit-appearance: none;
  position: relative;
}
.termsConditionsRow input[type='checkbox']:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 19px;
  background: url('./images/tik.svg') no-repeat center center;
  left: 1px;
  top: 0;
  opacity: 0;
  z-index: 1;
}
.termsConditionsRow input[type='checkbox']:checked {
  background: #326ec7;
  border-color: #326ec7;
}
.termsConditionsRow input[type='checkbox']:checked:after {
  opacity: 1;
}
.termsConditionsContent {
  width: calc(100% - 22px);
  text-align: left;
  padding-left: 12px;
}
.termsConditionsText {
  font-size: 18px;
  line-height: 27px;
  color: #000;
  cursor: pointer;
}
.termsConditionsText2 {
  font-size: 14px;
  line-height: 20px;
  color: #818080;
  margin-top: 10px;
  text-align: left;
}
.termsConditionsText2 span {
  color: #0012ff;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
}

/*------------------------
Reviews
------------------------*/
.chooseLogo {
  display: block;
  margin: 10px auto 0;
  width: 448px;
  max-width: 100%;
}

.chooseRow {
  width: 100%;
  margin: 25px auto 0;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.chooseColumn {
  width: 23%;
  text-align: center;
}
.chooseIcon {
  display: block;
  margin: 0 auto 5px;
  width: 45px;
}
.chooseColumnText {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 700;
}

.guaranteeBox {
  width: 100%;
  border-radius: 10px;
  margin: 40px auto 0;
  overflow: hidden;
}
.guaranteeHeading {
  width: 100%;
  background: #063f1e;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
}
.guaranteeSealBox {
  width: 80px;
}
.guaranteeSealBox img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.guaranteeHeadingRight {
  width: calc(100% - 140px);
  text-align: left;
  padding-left: 20px;
}
.guaranteeHeadingText {
  font-size: 26px;
  line-height: 32px;
  color: #fff;
  font-weight: 700;
}
.guaranteeBottom {
  width: 100%;
  background: #104c29;
  padding: 20px 30px;
  text-align: left;
}
.guaranteeText {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.checkoutReviewHeading {
  font-size: 22px;
  line-height: 28px;
  color: #000;
  font-weight: 700;
  margin-top: 40px;
  position: relative;
}
.checkoutReviewHeading:before {
  content: '';
  position: absolute;
  border-top: 1px solid #b0b0b0;
  top: 16px;
  left: 0;
  right: 0;
}
.checkoutReviewHeading span {
  display: inline-block;
  vertical-align: top;
  background: #f6f6f7;
  padding: 0 20px;
  position: relative;
}

.reviewBox {
  width: 100%;
  margin: 30px auto 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
}
.reviewColumn {
  width: 100%;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 20px 25px 25px;
  text-align: left;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
}
.reviewColumnText1 {
  font-size: 18px;
  line-height: 24px;
  color: #000;
  font-weight: 700;
}
.reviewStar {
  display: block;
  width: 93px;
  margin-top: 10px;
}
.reviewColumnText2 {
  font-size: 16px;
  line-height: 23px;
  color: #000;
  margin-top: 10px;
}
.reviewImage {
  display: block;
  margin-top: 15px;
  width: 76px;
  border-radius: 5px;
}
.verifyRow {
  width: 100%;
  margin-top: 25px;
  position: relative;
  padding-left: 55px;
}
.verifyShortName {
  position: absolute;
  left: 0;
  top: 52%;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  background: #9cd162;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  line-height: 41px;
  color: #fff;
  font-weight: 500;
}

.verifyName {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: 700;
}
.verifyText {
  font-size: 12px;
  line-height: 18px;
  color: #00ae35;
  margin-top: 3px;
}
.verifyIcon {
  display: inline-block;
  vertical-align: middle;
  margin: -4px 2px 0 0;
  width: 10px;
}

/*====Footer====*/
.footer {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fff;
  border-top: 1px solid #ccc;
  padding: 30px 0 45px;
}
.footer .container {
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.footerText1 {
  font-size: 15px;
  line-height: 21px;
  color: #3b3b3b;
  margin-top: 10px;
}
.footerText1 a {
  padding: 0 5px;
}

.forDesktop {
  display: block;
}
.forTablet,
.forMobile {
  display: none;
}
/*=====Media-Querry=====*/

@media only screen and (max-width: 1200px) {
  .checkoutLeft {
    padding-right: 4%;
  }
  .viewBoxColumn2 {
    font-size: 19px;
    line-height: 25px;
  }
  .checkoutRight {
    padding-left: 4%;
  }

  .packageProduct {
    width: 95px;
  }
  .packageProductInfo {
    width: calc(100% - 95px);
  }
}

@media only screen and (max-width: 999px) {
  .forDesktop {
    display: none;
  }
  .forTablet {
    display: block;
  }

  .container {
    width: 100%;
    padding: 0 1em;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: flex;
    display: flex;
    max-width: 40em;
    flex-direction: column;
    box-sizing: border-box;
  }

  .checkoutLeft {
    width: 100%;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 60px;
  }
  .checkoutRight {
    width: 100%;
    padding-left: 0;
    padding: 2rem 0 60px;
  }
  .checkoutRight:after {
    left: -100%;
    box-shadow: none;
  }

  .packageBlock {
    margin-bottom: 40px;
  }
  .packageProduct {
    width: 120px;
  }
  .packageProductInfo {
    width: calc(100% - 120px);
  }

  .summaryToggleMobile {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 1em 0;
    text-align: left;
    width: 100%;
    cursor: pointer;
    background: #f7f7f7;
  }
  .summaryToggle {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #0362bb;
    padding: 0 16px;
  }
  .summaryToggleText {
    width: 60%;
    font-size: 17px;
    line-height: 23px;
    color: #0362bb;
    text-align: left;
  }
  .orderSummaryToggleDropdown {
    margin-left: 4px;
    vertical-align: middle;
    -webkit-transition: fill 0.2s ease-in-out;
    transition: fill 0.2s ease-in-out;
    fill: #0362bb;
  }
  .orderSummaryToggle__icon {
    fill: #3c1a3f;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75em;
    margin-top: -4px;
  }
  .togglePrice {
    width: 35%;
    font-size: 21px;
    line-height: 26px;
    color: #000;
    text-align: right;
  }
  .orderSummaryToggle__dropdown {
    vertical-align: middle;
    -webkit-transition: fill 0.2s ease-in-out;
    transition: fill 0.2s ease-in-out;
    fill: #0362bb;
  }
  #toggleMobileCart.isOpened {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    -ms-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  #toggleMobileCart {
    width: 100%;
    padding: 0 16px;
  }
  #toggleMobileCart.isOpened {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    -ms-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

@media only screen and (max-width: 767px) {
  .forDesktop {
    display: none;
  }
  .forMobile {
    display: block;
  }
  .hideMobile {
    display: none;
  }

  .checkoutLeft {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 0;
  }
  .checkoutRight {
    width: 100%;
    padding-left: 0;
    padding: 1rem 0 2rem;
  }
  .checkoutRight:after {
    left: -100%;
    box-shadow: none;
    background: #fff;
    display: none;
  }

  .packageBlock {
    margin-bottom: 30px;
  }
  .switchOptionBox {
    margin: 0 auto;
  }
  .switchOption {
    font-size: 15px;
    line-height: 21px;
    padding: 8px 5px;
  }
  .subscribeShipText {
    font-size: 11px;
  }
  .packageSection {
    margin-top: 25px;
  }
  .package {
    width: 100%;
    padding: 25px 15px;
  }
  .packageProduct {
    width: 150px;
  }
  .packageProductInfo {
    width: calc(100% - 150px);
  }

  .summaryDiv {
    display: inline-block;
    vertical-align: top;
    width: calc(100% + 32px);
    margin-left: -16px;
    background: #f7f7f7;
  }
  #toggleMobileCart {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
  }
  #toggleMobileCart.isOpened {
    padding: 0;
    border: none;
  }

  .contactInformation,
  .deliveryInformation {
    margin-top: 25px;
  }
  .formField {
    margin: 5px 0;
  }
  .halfField {
    width: 100%;
  }
  .tooltip {
    right: 0;
    width: 110px;
    padding: 8px 5px 10px;
  }
  .tooltip:after {
    left: auto;
    right: 15px;
    transform: none;
  }

  .paymentFieldsBox {
    padding: 15px;
  }
  .shippingInformation {
    margin-top: 25px;
  }
  .securityText {
    font-size: 11px;
    line-height: 16px;
  }
  .termsText {
    font-size: 11px;
    line-height: 17px;
  }

  .expressCheckoutButton img {
    height: 26px;
  }
  .expressCheckoutText2 {
    margin-top: 10px;
  }

  .submitContainer {
    margin-top: 20px;
  }
  .submitButton {
    height: 65px;
    font-size: 20px;
    line-height: 64px;
  }
  .submitButton img {
    margin: -7px 10px 0 0;
    width: 16px;
  }

  .checkoutReviewHeading {
    margin-top: 20px;
    font-size: 20px;
  }
  .checkoutReviewHeading span {
    background: #fff;
    padding: 0 12px;
  }
  .chooseRow {
    row-gap: 30px;
  }
  .chooseColumn {
    width: 50%;
  }

  .guaranteeBox {
    margin: 30px auto 30px;
  }
  .guaranteeHeading {
    padding: 15px 20px;
  }
  .guaranteeSealBox {
    width: 80px;
  }
  .guaranteeHeadingRight {
    width: calc(100% - 90px);
  }
  .guaranteeHeadingText {
    font-size: 22px;
    line-height: 28px;
  }
  .guaranteeBottom {
    padding: 15px 30px;
  }

  .cartBox {
    margin: 20px 0 10px;
    padding: 5px 0;
  }
  .orderSummary {
    padding: 15px;
  }
  .orderBox {
    margin: 20px auto 0;
  }
  .orderLeftProductBox {
    width: 80px;
  }
  .orderProductInfo {
    width: calc(100% - 80px);
  }
  .orderProductInfoText2 {
    font-size: 15px;
    line-height: 16px;
  }
  .cartTable {
    font-size: 15px;
    line-height: 22px;
  }
  .cartTable span {
    font-size: 15px;
  }
  .totalTextLeft {
    font-size: 19px;
  }
  .cartTable .totalText span {
    font-size: 19px;
  }
  .cartSaveText {
    font-size: 16px;
    line-height: 22px;
    margin-top: 5px;
  }

  .securityText img {
    top: 2px;
  }

  .viewBox {
    margin: 25px auto 0;
    padding: 8px 10px;
  }
  .viewBoxColumn1 {
    width: 16px;
  }
  .viewBoxColumn2 {
    font-size: 14px;
    line-height: 21px;
    padding-left: 7px;
  }

  .checkoutHeading {
    font-size: 20px;
    line-height: 26px;
  }

  .termsConditionsRow input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }
  .termsConditionsRow input[type='checkbox']:after {
    width: 15px;
    height: 18px;
  }
  .termsConditionsContent {
    width: calc(100% - 20px);
    padding-left: 10px;
  }
  .termsConditionsText {
    font-size: 17px;
    line-height: 22px;
  }
  .termsConditionsText2 {
    font-size: 13px;
    line-height: 18px;
  }

  .reviewBox {
    margin: 20px auto 0;
  }
  .reviewColumn {
    padding: 15px 15px 20px;
  }
  .reviewStar {
    width: 80px;
  }
  .reviewColumnText1 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.3px;
  }
  .reviewColumnText2 {
    font-size: 15px;
    line-height: 23px;
  }
  .verifyRow {
    margin-top: 20px;
    padding-left: 60px;
  }
  .verifyImage {
    margin-top: -25px;
    width: 50px;
  }
  .verifyIcon {
    margin: 0px 3px 0 0;
  }

  .footer {
    padding: 15px 0 25px;
  }
  .footerText1 {
    font-size: 13px;
    line-height: 19px;
  }
}

.inputGroup {
  margin-bottom: 15px;
  text-align: left;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.inputGroup input,
.inputGroup select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.inputGroup .errorInput {
  border-color: #f00;
}

.errorMessage {
  text-align: left;
  color: #f00;
  font-size: 14px;
  margin-top: 5px;
}

.formSelect {
  position: relative;
}

.formSelect select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('./images/select-arrow.png') no-repeat right 10px center;
  padding-right: 30px;
}

.phoneInputWrapper {
  display: flex;
  align-items: center;
}

.phonePrefix {
  padding: 10px;
  background: #f1f1f1;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.phoneInputWrapper input {
  border-radius: 0 4px 4px 0;
}

.termsCheckbox {
  display: flex;
  align-items: center;
}

.termsCheckbox input {
  margin-right: 10px;
}

.bigCheckbox {
  width: 20px;
  height: 20px;
}

.smsText {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.declineError {
  color: #f00;
  font-size: 14px;
  margin-top: 10px;
}

.termsText {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.termsText span {
  color: #0012ff;
  text-decoration: underline;
  cursor: pointer;
}
.declineError {
  margin-top: 10px;
  padding: 20px;
  background-color: #f8d7da;
  border-radius: 5px;
  border: 2px solid #f5c6cb;
}

.declineError p {
  color: red;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  line-height: 1.4;
}
