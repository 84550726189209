@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap")

@font-face
  font-family: "AvenirNextProRegular"
  src: url("../public/fonts/AvenirNextProRegular/font.woff2") format("woff2"), url("../public/fonts/AvenirNextProRegular/font.woff") format("woff")

@font-face
  font-family: "AvenirNextProBold"
  src: url("../public/fonts/AvenirNextProBold/font.woff2") format("woff2"), url("../public/fonts/AvenirNextProBold/font.woff") format("woff")

html
  scroll-behavior: smooth

*
  box-sizing: border-box
  outline: none

#root
  display: flex
  flex-direction: column
  min-height: 100vh

body
  display: flex
  flex-direction: column
  min-height: 100vh
  min-width: 320px
  color: black
  font-size: 16px
  font-family: "Roboto", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-smoothing: antialiased

main
  display: flex
  flex-direction: column
  flex: 1

img, video
  max-width: 100%
  max-height: 100%

button
  cursor: pointer

b, strong
  font-weight: 700

.inline-block
  display: inline-block

.text-center
  text-align: center

.FreeProducts
  .free-product-list-list--modal
    max-height: 100%
    overflow-y: auto

.container
  display: flex
  flex-direction: column
  flex: 1
  width: 1000px
  max-width: 100%
  margin: 0 auto
  padding: 0 20px
