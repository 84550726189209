.ColorSelector
  .special-offer
    display: flex
    flex-direction: column
    align-items: center
    width: 700px
    margin: 0 auto
    max-width: 100%
    padding-top: 30px
    padding: 20px

    &__title
      margin-bottom: 10px
      font-weight: bold
      font-size: 44px
      text-align: center
      color: #0097fe

      @media (max-width: 768px)
        font-size: 32px

    &__headline
      margin-bottom: 24px
      font-size: 44px
      font-weight: bold
      text-align: center
      line-height: 1.2

      @media (max-width: 768px)
        font-size: 32px

    &__subHeadline
      margin-bottom: 20px
      font-size: 34px
      font-weight: bold
      text-align: center

      @media (max-width: 768px)
        font-size: 22px

    &__prices
      display: flex
      flex-direction: column
      margin-bottom: 10px
      font-size: 22px
      font-weight: bold

      &--old
        margin-bottom: 6px

        b
          color: red

      &--new
        b
          color: #3bd108

    &__img
      width: 500px
      max-width: 100%
      margin-bottom: 15px

    &__select
      width: 450px
      max-width: 100%
      margin-bottom: 20px
      padding: 10px
      border-color: #818181
      border-radius: 4px
      font-size: 16px
      outline: none

    &__buttons
      display: flex
      flex-direction: column
      align-items: center
      width: 100%
      margin-top: 30px

      &--add
        width: 550px
        max-width: 100%
        margin-bottom: 20px
        padding: 22px
        border: none
        border-radius: 8px
        background-color: #3cd108
        color: #ffffff
        font-size: 20px
        font-weight: 900
        cursor: pointer
        transition: 0.2s all
        outline: none

        @media (max-width: 768px)
          font-size: 26px
          padding: 15px 8px

        &:hover
          background: darken(#3cd108, 10)

      &--skip
        margin-bottom: 20px
        background: none
        border: none
        color: #797979
        font-size: 14px
        text-decoration: none
        font-weight: normal
        cursor: pointer
        transition: 0.2s all

  header.header
    display: none

  .page-main > .container
    width: 100%
    max-width: 100%
    padding: 0

  .page-header
    padding: 5px
    background-color: #ff0000
    font-size: 14px
    color: white

    @media (max-width: 768px)
      padding-bottom: 0
      padding-left: 0
      padding-right: 0

    &__steps
      height: 20px

      @media (max-width: 768px)
        margin-top: 4px
        background-color: #fff

  .page-header-inner
    display: flex
    justify-content: space-between
    align-items: center

    @media (max-width: 768px)
      flex-direction: column
