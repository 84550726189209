.holiday-banner
  position: relative
  width: 960px
  max-width: 100%
  margin-left: auto
  margin-right: auto

  &__img
    width: 100%
    max-width: 100%
    object-fit: contain

  &__img--mob
    display: none

  &__discount
    position: absolute
    right: 15px
    top: 50%
    padding: 5px 15px
    border: 2px dashed red
    font-weight: bold
    color: red
    border-radius: 5px
    background-color: #fff
    font-size: 20px
    white-space: nowrap
    transform: translateY(-50%)

    @media (max-width: 768px)
      top: auto
      right: 50%
      bottom: 8px
      transform: translateX(50%)

  &__img--desk
    @media (max-width: 768px)
      display: none

  &__img--mob
    @media (max-width: 768px)
      display: block
