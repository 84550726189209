.thank-you
  padding: 40px 0

  &__title
    margin-bottom: 15px
    font-weight: 700
    font-size: 30px
    line-height: 1.2

  &__text
    margin-bottom: 30px
    padding-bottom: 30px
    border-bottom: 1px solid #cccccc
    line-height: 1.4

  &__order-line
    margin-bottom: 10px
    font-weight: bold

.try-again
  border: none
  background-color: transparent
  color: blue
  font-size: 14px
  text-decoration: underline
