@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap")

.container
  display: flex
  flex-direction: column
  width: 100%
  max-width: 1140px
  margin: 0 auto
  padding-left: 16px
  padding-right: 16px
  font-family: "Montserrat", sans-serif

.header
  padding: 10px 0
  background-color: #fcc506

.headerInner
  display: flex
  justify-content: space-between

  @media (max-width: 768px)
    flex-direction: column
    align-items: center

.headerLeft
  display: flex
  align-items: center
  font-size: 18px

  @media (max-width: 768px)
    margin-bottom: 10px
    font-size: 16px

  svg
    margin-right: 10px
    height: 24px

.headerRight
  display: flex
  font-size: 24px

  @media (max-width: 768px)
    font-size: 20px

.headerRightTime
  display: flex
  flex-direction: column
  align-items: center
  margin: 0 14px

  p
    margin-top: 5px
    font-size: 12px

// Hero
.hero
  background-image: url(./img/promo-bg.png)
  background-repeat: no-repeat
  background-size: cover

.heroInner
  display: flex
  justify-content: space-between
  margin-top: 20px
  padding: 20px

.heroLeft
  display: flex
  justify-content: center
  padding-left: 10%

  @media (max-width: 768px)
    padding-left: 0

  img
    width: 200px

    @media (max-width: 768px)
      width: 150px

.heroRight
  display: flex
  align-items: center
  padding-right: 10%

  @media (max-width: 768px)
    padding-right: 0

  img
    max-width: 200px

    @media (max-width: 768px)
      max-width: 150px

// Checkout
.checkout
  display: flex
  padding-top: 20px
  padding-bottom: 40px

  @media (max-width: 768px)
    flex-direction: column
    max-width: 540px
    margin: 0 auto

.checkoutLeft
  width: 50%
  margin-right: 10px

  @media (max-width: 768px)
    width: 100%
    margin-right: 0
    margin-bottom: 15px

.benefitsAndDiscount
  display: flex
  flex-direction: column
  margin-bottom: 15px
  padding: 10px
  border: 1px solid #e4e4e4
  box-shadow: 0 0 16px -2px #0000001a
  border-radius: 4px

.benefits
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 15px
  padding-bottom: 15px
  border-bottom: 1px solid #e4e4e4

  @media (max-width: 768px)
    flex-wrap: wrap

.benefit
  display: flex
  flex-direction: column
  align-items: center
  color: #4e596d
  text-align: center
  font-weight: bold

  @media (max-width: 768px)
    width: calc(50% - 10px)
    flex-direction: row
    text-align: left
    margin: 5px
    margin-bottom: 20px
    font-size: 14px

  img
    height: 48px
    margin-bottom: 15px

    @media (max-width: 768px)
      margin-bottom: 0
      margin-right: 10px

.discount
  display: flex
  align-items: center

.discountImage
  height: 90px
  width: 90px
  display: flex
  justify-content: center
  align-items: center
  flex: none
  margin-right: 15px
  border-radius: 50%
  border: 7px solid #5dcd73
  color: #fff
  text-align: center
  font-size: 28px
  font-weight: 900
  line-height: 0.8
  background-color: #66a34f
  box-shadow: 0 0 3px 3px #f0dd7a
  text-shadow: 0 0 6px #474747

.discountContent
  font-weight: bold
  line-height: 20px

  p
    margin-bottom: 10px
    color: #4e596d

    s
      color: black

    b
      color: #5dcd73

// STEP 1
.step1
  padding: 15px
  border: 1px solid #e4e4e4
  border-radius: 4px
  box-shadow: 0 0 16px -2px #0000001a

.stepTitle
  margin-bottom: 15px
  font-weight: bold

  i
    margin-right: 5px
    font-size: 20px

.offerSelector
  display: flex
  margin-bottom: 20px
  padding: 5px
  border-radius: 4px
  background-color: #f1efef
  border: 1px solid #dedede

.offerSelectorButton
  width: 50%
  padding: 8px
  text-align: center
  font-weight: bold
  font-size: 14px
  text-transform: uppercase
  font-family: "Montserrat", sans-serif
  user-select: none
  border: none
  background-color: transparent
  border-radius: 4px

  p
    color: black

    span
      display: block
      color: red

  b
    color: #929292
    font-size: 12px

.offerSelectorButtonActive
  background-color: #fff
  color: #23a2ed
  box-shadow: 0 0 6px 2px #00000029

.products
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.product
  position: relative
  display: flex
  align-items: center
  width: calc(50% - 10px)
  margin: 5px
  padding: 10px
  box-shadow: 0 0 8px #00000029
  cursor: pointer
  overflow: hidden
  border: 1px solid #858585
  border-radius: 4px

  @media (max-width: 768px)
    width: 100%
    margin: 0
    margin-bottom: 10px

.productActive
  border: 3px solid #25a2ed

.productPopular
  position: absolute
  width: 200px
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  background-color: #25a2ed
  color: #fff
  font-size: 12px
  font-weight: 700
  line-height: 16px
  text-transform: uppercase
  transform: rotate(-33deg)
  inset: 20px auto auto -51px
  z-index: 1

.productBestValue
  position: absolute
  width: 200px
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  background-color: #fcc506
  color: #000
  font-size: 12px
  font-weight: 700
  line-height: 16px
  text-transform: uppercase
  transform: rotate(-33deg)
  inset: 20px auto auto -51px
  z-index: 1

.productSoldOut
  user-select: none
  pointer-events: none
  cursor: not-allowed
  opacity: 0.5

.productSoldOutBadge
  position: absolute
  width: 200px
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  background-color: #dfdfdf
  color: #000
  font-size: 12px
  font-weight: 700
  line-height: 16px
  text-transform: uppercase
  transform: rotate(-33deg)
  inset: 20px auto auto -51px
  z-index: 1

.productImage
  max-width: 200px
  margin-right: 10px

.productContent
  display: flex
  flex-direction: column
  text-align: center

  b
    margin-bottom: 10px
    font-size: 32px

  p
    margin-bottom: 5px
    font-size: 14px
    line-height: 1.2

    strong
      font-weight: normal

  s
    margin-bottom: 5px
    color: #9aa0ab

  span
    color: #00c249
    font-size: 24px
    font-weight: 700

p.productSubtitle
  font-size: 15px
  font-weight: 600

.checkoutRight
  width: 50%
  margin-left: 10px

  @media (max-width: 768px)
    width: 100%
    margin-left: 0

  .stepTitle
    margin-bottom: 25px

.expressCheckout
  margin-bottom: 20px
  border-radius: 4px
  box-shadow: 0 0 16px -2px #0000001a

  @media (max-width: 768px)
    margin-bottom: 20px
    margin-top: 30px

.orderForm
  padding: 15px
  border: 1px solid #e4e4e4
  border-radius: 4px
  box-shadow: 0 0 16px -2px #0000001a

  label
    display: block
    margin-bottom: 8px
    font-weight: bold

  input, select
    width: 100%
    height: 40px
    color: #000
    background-color: #fff
    padding: 10px
    border: 1px solid #e4e4e4
    border-radius: 4px
    font-family: "Montserrat", sans-serif
    font-size: 16px

    &::placeholder
      color: #9aa0ab
      font-family: "Montserrat", sans-serif
      font-size: 16px

.formSelect
  position: relative
  width: 100% !important

  select
    width: 100%
    appearance: none

  &:after
    content: "▾"
    position: absolute
    right: 10px
    top: 50%
    transform: translateY(-50%)
    pointer-events: none

.formRow
  display: flex

  div
    width: 50%
    margin-right: 10px

    &:last-child
      margin-right: 0

.phoneInputWrapper
  display: flex
  align-items: center

.phonePrefix
  background-color: #f1f1f1
  padding: 11px
  border: 1px solid #ccc
  border-right: none
  border-radius: 4px 0 0 4px
  font-weight: bold
  color: #333

input[type='tel']
  flex: 1
  border-radius: 0 4px 4px 0

.formShipping
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  margin-bottom: 20px
  padding: 12px
  margin-bottom: 20px
  border: 1px solid #e4e4e4
  border-radius: 4px
  font-family: "Montserrat", sans-serif

.creditCardBlock
  padding: 20px
  border: 1px solid #e6e7ea
  border-radius: 4px

.creditCardBlockHeader
  display: flex
  justify-content: space-between
  font-size: 20px
  border-bottom: 1px solid #e6e7ea
  padding-bottom: 15px
  margin-bottom: 15px

.creditCardBlockHeaderCards
  display: flex
  align-items: center

  img
    height: 25px
    margin-right: 10px

.submitButton
  width: 100%
  padding: 15px
  color: white
  text-align: center
  background-color: #00c249
  border-radius: 0px
  font-size: 20px
  font-weight: 700
  font-family: "Montserrat", sans-serif
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.05)
  border: none
  transition: background-color 0.2s

  &:hover
    background-color: #00a63f

  &:disabled
    background-color: #ccc
    cursor: not-allowed

.declineError
  margin-top: 10px
  padding: 20px
  background-color: #f8d7da
  border-radius: 5px
  border: 2px solid #f5c6cb

  p
    color: red
    font-size: 14px
    text-align: center
    font-weight: bold
    line-height: 1.4

.termsText
  margin-bottom: 20px
  font-size: 12px
  line-height: 1.2
  color: #9aa0ab

  span
    color: #1e96ff
    cursor: pointer
    text-decoration: underline

.termsCheckbox
  display: flex
  align-items: baseline
  margin-bottom: 10px

  input
    width: 20px
    height: 20px
    cursor: pointer

  label
    font-weight: normal
    margin-bottom: 0

    b
      color: blue
      cursor: pointer

.bigCheckbox
  position: relative
  top: -2px
  left: -4px
  transform: scale(1.5)
  transform-origin: top left
  margin-right: 10px
  margin-left: 0
  margin-top: 0
  margin-bottom: 0

.smsText
  font-size: 12px
  line-height: 1.2
  font-style: italic
  color: #9aa0ab

.checkoutRightFooter
  img
    padding: 10px 0
    border-bottom: 1px solid #e4e4e4

  p
    padding: 20px 10px
    text-align: center
    color: #4a4a4a

    b
      display: block
      margin-bottom: 10px

      span
        color: #00c249

.checkoutRightFooterBenefits
  display: flex
  flex-direction: column
  margin-top: 30px
  margin-left: 10px

.checkoutRightFooterBenefit
  display: flex
  align-items: flex-start

  p
    margin-bottom: 20px
    margin-left: 10px
    text-align: left
    border-bottom: none
    padding: 0

    b
      display: inline

  i
    color: #335fff

.inputGroup
  margin-bottom: 20px

.errorMessage
  color: red
  font-size: 0.8em
  margin-top: 10px
  line-height: 1.2

.inputMessage
  color: #4b4b4b
  font-size: 0.8em
  margin-top: 10px
  line-height: 1.2

.errorInput
  border-color: red

input,
select,
textarea
  border: 1px solid #ccc

input.errorInput,
select.errorInput,
textarea.errorInput
  border-color: red

select.errorInput
  border-color: red
