.container
  display: flex
  align-items: center
  padding: 10px
  border: 1px solid #e6e7ea
  border-radius: 4px

  img
    height: 75px
    margin-right: 10px
    padding: 0
    border-bottom: none !important

.text
  b
    display: block
    margin-bottom: 10px

    @media (max-width: 768px)
      margin-bottom: 5px

  p
    padding: 0 !important
    text-align: left !important
    line-height: 1.4

    @media (max-width: 768px)
      font-size: 14px
      line-height: 1.3
